.login-profile {
  margin-top: -50px; //TODO: dev-abdullah gave margin
  max-width: 425px;
  .profile-header {
    padding: 13px 19px 13px 16px;
    background-color: white;
  }
  .ant-divider-horizontal {
    margin: 0px !important;
  }
}

body .second-header-login-mobile-screen {
  padding: 13px 19px 13px 16px;
  background-color: white;
  text-align: center;
}

body .second-header-login-mobile-screen-text {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  text-align: center;
}

body .mobile-login-screen-back-div {
  position: absolute;
  left: 24px;
  top: 16px;
}
@media (max-width: 1024px) and (min-width: 768px) {
  body .login-container {
    max-width: 100% !important;
  }
  body #Mobile-Login-Form {
    max-width: 100%!important;
  }
  .mobile-services .service {
    justify-content: unset!important;
  }
}

.outer-spinner-div-mobile{
  display: flex;
  justify-content: center;
  padding: 175px 0px;
  background-color: white;
  margin: 10px;
}